<template>
  <div class="row">

    <transition name="slide-fade" appear v-if="mostrarOrden !== false">
        <div class="col-12 col-md-6">
          <h5>Datos de la Orden</h5>
          <table class="table table-info table-light">
            <tbody>
              <tr>
                <td class="table-dark">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'list-alt', }" class="icon btn-icon"/> Folio Orden
                </td>
                <td>{{ ordenData.Folio }}</td>
              </tr>
              <tr>
                <td class="table-dark">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'calendar-alt', }" class="icon btn-icon"/> Fecha de Orden
                </td>
                <td>{{ ordenData.Fecha }}
                  <a class="link" @click="msgBoxEditFechaOrden" v-if="$editar(['Proveedores'])" v-b-popover.hover.bottom="'Editar Fecha de la Orden'">
                    <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'edit', }" class="icon btn-icon" />
                  </a>
                </td>
              </tr>
              <tr>
                <td class="table-dark">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'building', }" class="icon btn-icon"/> Contratante
                </td>
                <td>{{ ordenData.Empresa }}</td>
              </tr>
              <tr>
                <td class="table-dark">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle', }" class="icon btn-icon"/> Estatus
                </td>
                <td>
                  <v-select
                  v-if="$editar(['Ordenes'])"
                  class="form-select"
                  placeholder="Cambiar estatus"
                  label="label"
                  :options="[{ label: 'Activa', value: 1 }, { label: 'Cerrada', value: 0 }]"
                  :reduce="(option) => option.value"
                  :searchable="false"
                  :clearable="false"
                  @option:selected="changeEstatusOrden"
                  v-model="ordenEstatus">
                    <template v-slot:option="option">
                      <b-badge class="font-size-12" :variant="option.value === 1 ? 'success' : 'secondary'">{{ option.label }}</b-badge>
                    </template>
                    <template #selected-option="{ label, value }">
                      <b-badge class="font-size-12" :variant="value === 1 ? 'success' : 'secondary'">{{ label }}</b-badge>
                    </template>
                  </v-select>
                  <span v-else>
                    <b-badge class="font-size-12" variant="success" v-if="ordenData.Estatus === 1">Activa</b-badge>
                    <b-badge class="font-size-12" variant="secondary" v-if="ordenData.Estatus === 0">Cerrada</b-badge>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="table-dark">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'file-pdf', }" class="icon btn-icon"/> Orden de compra
                </td>
                <td class="spacer td-icon">
                  <button type="button"
                    v-b-popover.hover.html="ordenData.OrdenId ? `<strong>Nombre:</strong> ${ordenData.NomArchivo}<br><strong>Fecha:</strong> ${ordenData.FechaCarga}<br><strong>Autor:</strong> ${ordenData.Autor}` : 'Sin archivo'" title="Ver Orden de compra"
                    :class="ordenData.OrdenId ? 'btn btn-primary' : 'btn btn-link'"
                    :disabled="!ordenData.OrdenId || loading"
                    v-if="ordenData.OrdenId"
                    @click="$descargar(['Ordenes']) && ordenData.OrdenId ? downloadOrden() : undefined">
                    <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'file-pdf', }" class="icon btn-icon"
                      v-if="!loading"/>
                    <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'spinner', }" class="icon btn-icon" spin
                      v-if="loading"/>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </transition>

    <transition name="slide-fade" appear v-if="mostrarCarga !== false">
      <div class="col-12 col-md-6">
        <periodo-proyecto :datos="ordenData" :provId="provId" @submit="getOrden"/>
        <dias-carga :datos="ordenData" :provId="provId" @submit="getOrden"/>
        <table class="table table-info table-light">
          <tbody>
            <tr>
              <td class="table-dark">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'file-archive', }" class="icon btn-icon"/> Descargar Expediente
              </td>
              <td class="spacer td-icon">
                <button type="button"
                  v-b-popover.hover.bottom="ordenData.OrdenId ? 'Se descargará el archivo de la orden de compra y todos los archivos cargados mensualmente en formato zip.' : 'Sin archivos'" title="Descargar archivos de la Orden"
                  :class="ordenData.OrdenId ? 'btn btn-primary' : 'btn btn-link'"
                  :disabled="!ordenData.OrdenId || loading"
                  v-if="ordenData.OrdenId"
                  @click="$descargar(['Detalle de Orden']) && ordenData.OrdenId ? downloadExpediente() : undefined">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'download', }" class="icon btn-icon"
                    v-if="!loadingExp"/>
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'spinner', }" class="icon btn-icon" spin
                    v-if="loadingExp"/>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </transition>

    <transition name="slide-fade" appear v-if="mostrarEstatus  !== false">
      <div class="col-12">
        <estatus-cards :periodo="ordenData.MaxPeriodo" :listaNegra="listaNegra" :porcentajeEnvio="ordenData.Enviados" :porcentajeValido="ordenData.Validos"></estatus-cards>
      </div>
    </transition>

    <b-modal
      id="bv-modal-fecha-orden" size="sm" centered hide-footer content-class="site-modal"
      :header-text-variant="'light'"
      v-if="$editar(['Proveedores'])">
      <template #modal-title>
        Fecha de Orden
      </template>
      <form class="form-site inner inner-sm" @submit.prevent="actualizarFechaOrden()">
        <div class="form-row">
          <div class="form-holder form-holder-2">
            <fieldset :class="validation.hasError('fechaIniProy') ? 'select-error' : ''">
              <legend>Fecha de la Orden</legend>
              <date-picker
                type="date"
                :placeholder="'DD-MM-AAAA'"
                :clearable="false"
                :format="'DD-MM-YYYY'"
                :formatter="formatedFecha"
                v-model="fechaOrden">
                <template v-slot:header="{ emit }">
                  <button class="mx-btn mx-btn-text" @click="emit(new Date())">* HOY</button>
                </template>
              </date-picker>
            </fieldset>
          </div>
        </div>
        <button type="submit" class="btn btn-block btn-action mb-2" block>
          <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
          <font-awesome-icon v-if="!loading" :icon="{ prefix: 'fa', iconName: 'save', }" class="icon btn-icon" />Guardar
        </button>
      </form>
    </b-modal>

  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import DiasCarga from '@/components/DiasCarga.vue';
import PeriodoProyecto from '@/components/PeriodoProyecto.vue';
import EstatusCards from '@/components/EstatusCards.vue';
import moment from 'moment';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  name: 'OrdenData',
  components: {
    DiasCarga,
    PeriodoProyecto,
    EstatusCards,
  },
  props: [
    'provId',
    'ordenId',
    'mostrarOrden',
    'mostrarCarga',
    'mostrarEstatus',
    'updateScreen',
  ],
  data() {
    return {
      loading: false,
      loadingExp: false,
      loadingDown: false,
      loadingUp: false,
      ordenData: {},
      ordenEstatus: null,
      estatusData: {},
      listaNegra: {},
      periodo: this.$formatDateMonth(moment().subtract(1, 'M').toDate()),
      fechaOrden: null,
      formatedFecha: {
        stringify: (date) => this.$formatDateMX(date) || '',
      },
    };
  },
  mounted() {
    this.getData();
  },
  validators: {
    fechaOrden(value) { return Validator.value(value).required('Campo requerido'); },
  },
  methods: {
    getData() {
      this.getOrden();
      this.getEstatus();
      this.getListaNegra();
    },
    getEstatus() {
      apiClient.get('/estatus')
        .then((res) => {
          this.estatusData = res.data;
        })
        .catch(() => {});
    },
    getListaNegra() {
      apiClient.get('/perfil/lista-negra', {
        params: {
          ProvId: this.provId,
        },
      })
        .then((res) => {
          this.listaNegra = res.data;
        })
        .catch(() => {});
    },
    getOrden() {
      apiClient.get(`/ordenes/${this.ordenId}/datos`, {
        params: {
          ProvId: this.provId,
        },
      })
        .then((res) => {
          this.ordenData = res.data;
          this.ordenEstatus = this.ordenData.Estatus;
          this.$emit('getData', this.ordenData);
        })
        .catch(() => {
          this.$router.replace('/');
        });
    },
    downloadOrden() {
      this.loading = true;
      apiClient.get(`/ordenes/${this.ordenId}/archivo`, {
        responseType: 'blob',
        params: {
          ProvId: this.provId,
        },
      })
        .then((res) => {
          const fileUrl = URL.createObjectURL(res.data);
          URL.revokeObjectURL(res.data);
          this.loading = false;
          this.loadingDown = false;
          window.open(fileUrl, '_blank');
          // URL.revokeObjectURL(fileUrl);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    changeEstatusOrden(selectedOption) {
      apiClient.put(`/ordenes/${this.ordenId}/estatus`, {
        ProvId: this.provId,
        idEstatus: selectedOption.value,
      })
        .then(() => { this.getOrden(); })
        .catch(() => {});
    },
    downloadExpediente() {
      this.loading = true;
      this.loadingExp = true;
      apiClient.get(`/orden/${this.ordenId}/expediente`, {
        responseType: 'blob',
        params: {
          ProvId: this.provId,
        },
      })
        .then((res) => {
          const fileUrl = URL.createObjectURL(res.data);
          const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1];
          const link = document.createElement('a');
          link.href = fileUrl;
          // console.log(fileName);
          link.setAttribute('download', fileName || 'Archivo.zip');
          link.click();
          setTimeout(() => URL.revokeObjectURL(fileUrl), 0);
          setTimeout(() => URL.revokeObjectURL(res.data), 0);
          // URL.revokeObjectURL(fileUrl);
          // URL.revokeObjectURL(res.data);
          this.loading = false;
          this.loadingExp = false;
        })
        .catch(() => {
          this.loading = false;
          this.loadingExp = false;
        });
    },
    msgBoxEditFechaOrden() {
      this.$bvModal.msgBoxConfirm('Si continua, se actualizará solamente la Fecha de esta Orden de Compra.', {
        title: 'Editar Fecha Orden',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si Continuar',
        cancelTitle: 'Cancelar',
        headerClass: 'text-light',
        footerClass: 'bg-light p-2',
        hideHeaderClose: true,
        centered: true,
      })
        .then((confirm) => {
          if (confirm) this.openEditFechas();
        })
        .catch(() => {});
    },
    openEditFechas() {
      this.fechaOrden = this.$mxToDate(this.ordenData.Fecha);
      this.$bvModal.show('bv-modal-fecha-orden');
    },
    actualizarFechaOrden() {
      this.$validate().then((success) => {
        if (success) {
          this.loading = true;
          apiClient.put(`/orden/${this.ordenData.OrdenId}/fecha`, {
            ProvId: this.provId,
            fechaOrden: this.$formatDate(this.fechaOrden),
          })
            .then(() => {
              this.$bvModal.hide('bv-modal-fecha-orden');
              this.loading = false;
              this.getOrden();
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  computed: {
    porcentageValido() {
      const totalArchivos = this.filesList.length || 1;
      const objValidos = this.filesList.length ? this.filesList.filter((archivo) => archivo.Estatus === 'Válido') : {};
      const totalValidos = Object.keys(objValidos).length;
      return Math.round((totalValidos / totalArchivos) * 100);
    },
  },
  watch: {
    updateScreen() {
      this.getData();
    },
  },
};

</script>

<style lang="scss" scoped>

</style>
